.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  animation: spin infinite 20s linear;
  height: 30px;
}

.App-header {
  background-color: #02acf1;
  padding: 20px;
  color: white;
  text-align: center;
}

.App-intro {
  font-size: large;
}

